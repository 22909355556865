import wx from 'weixin-js-sdk'
import { getWxSign } from '../api/wx'
/* eslint-disable */
let wxConfig = async (url, option) => {
  let res = await getWxSign({ url: url })
  if (!localStorage.getItem('wxFig')) {
    localStorage.setItem('wxFig', JSON.stringify(res.data))
  }
  wx.config({
    debug: false, // 开启调试模式
    appId: res.data.appId, // 必填，公众号的唯一标识
    timestamp: res.data.timestamp, // 必填，生成签名的时间戳
    nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
    signature: res.data.signature, // 必填，签名，见附录1
    jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'startRecord', 'stopRecord', 'onVoiceRecordEnd', 'playVoice', 'pauseVoice', 'stopVoice', 'onVoicePlayEnd', 'uploadVoice', 'translateVoice', 'downloadVoice'], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
  })
  wx.ready(res => {
    wx.updateAppMessageShareData({
      title: '祝语APP 下载', // 分享标题
      desc: '祝语一键祝福平台，点击下载祝语APP', // 分享描述
      link: location.origin,
      imgUrl: 'https://api.mayiyahei.net/img/avatar.png', // 分享图标
      success: function () {
        console.log('邀约分享朋友')
      },
    })
    wx.updateTimelineShareData({
      title: '祝语APP 下载', // 分享标题
      desc: '祝语一键祝福平台，点击下载祝语APP', // 分享描述
      link: location.origin,
      imgUrl: 'https://api.mayiyahei.net/img/avatar.png', // 分享图标
      success: function () {
        console.log('邀约分享朋友圈')
      },
    })
  })
  wx.error(function (res) {
    console.log('wx.error', res)
    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
  })
}

export default wxConfig
