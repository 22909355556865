import axios from 'axios'

// 创建axios
const request = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 5000, // 超时时间
})

// 请求拦截器
request.interceptors.request.use(
  config => {
    let _headers = {
      Authorization: localStorage.getItem('token'),
      'App-type': 'wish',
      'Content-Type': localStorage.getItem('CONTENTTYPE') ? localStorage.getItem('CONTENTTYPE') : 'application/json',
      source: 3,
    }
    if (config && config.headers) {
      // 多一步判断
      config.headers = _headers
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器
request.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    switch (error && error.response && error.response.status) {
      case 401:
        error.message = '未登录'
        break
      case 403:
        error.message = '拒绝访问'
        break
      case 404:
        error.message = '未找到访问地址'
        break
      case 500:
        error.message = '系统错误'
        break
      default:
    }
    return Promise.reject(error)
  }
)

export default request
