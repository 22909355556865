import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 引入vantUI（按需引入组件）
// import '@/plugins/vant'
import { Toast, Popup } from 'vant'
import 'vant/lib/index.css'
import './assets/css/reset.scss'
import wxConfig from './utils/wxapi'
wxConfig(window.location.href)

Vue.config.productionTip = false
Vue.use(Toast)
Vue.use(Popup)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
